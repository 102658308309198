<template>
  <!-- TODO REMOVE THIS SINCE IT IS NO LONGER BEING USED -->
  <div>
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      class="elevation-1"
      :right="$vuetify.rtl"
      :light="menuTheme === 'light'"
      :dark="menuTheme === 'dark'"
    >
      <!-- Navigation menu info -->
      <template v-slot:prepend>
        <div class="pa-2 pb-0">
          <div class="title font-weight-bold text-uppercase primary--text">
            {{ product.name }}
          </div>
          <div class="overline grey--text">{{ product.version }}</div>
        </div>
      </template>

      <!-- Navigation menu -->
      <main-menu :dashboard="dashboard" :menu="subscriptionNavigation.menu" />

      <!-- Navigation menu footer -->
      <template v-slot:append>
        <!-- Footer navigation links -->
        <div class="pa-1 text-center">
          <v-btn
            v-for="(item, index) in navigation.footer"
            :key="index"
            @click="navigateTo(item.link)"
            small
            text
          >
            {{ item.key ? item.key : item.text }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar
      app
      :color="isToolbarDetached ? 'surface' : undefined"
      :flat="isToolbarDetached"
      :light="toolbarTheme === 'light'"
      :dark="toolbarTheme === 'dark'"
    >
      <v-card
        class="flex-grow-1 d-flex"
        :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']"
        :flat="!isToolbarDetached"
      >
        <div class="d-flex flex-grow-1 align-center">
          <!-- search input mobile -->
          <v-container fluid class="pa-0" v-if="showSearch">
            <toolbar-search-bar mobile :showSearch.sync="showSearch" />
          </v-container>

          <div v-else class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon text
                                @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>

            <v-spacer class="d-none d-lg-block"></v-spacer>

            <!-- search input desktop -->
            <toolbar-search-bar />
            <v-spacer class="d-block d-sm-none"></v-spacer>

            <v-btn class="d-block d-sm-none" icon @click="showSearch = true">
              <v-icon>$search</v-icon>
            </v-btn>

            <toolbar-apps />

            <toolbar-theme :isDarkTheme="isDarkTheme" />
            <toolbar-notifications></toolbar-notifications>
            <toolbar-user />
          </div>
        </div>
      </v-card>
    </v-app-bar>
    <v-main>
      <v-container
        class="fill-height"
        :fluid="!isContentBoxed"
        :class="{ 'mt-1 pa-0 py-2': $vuetify.breakpoint.mobile }"
      >
        <v-layout>
          <slot></slot>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script lang="ts">
// navigation menu configurations
import config from "@main//configs";
import MainMenu from "@main/components/navigation/MainMenu.vue";
import ToolbarUser from "@main/components/toolbar/ToolbarUser.vue";
import ToolbarApps from "@main/components/toolbar/ToolbarApps.vue";
import theme from "@main//configs/theme";
import { computed, defineComponent, ref } from "vue";
import {useRouteComponents} from "@main/composables/helpers";
import {useStore} from "../providers";
import {UIGetters} from "../store/ui-customizations";
import ToolbarTheme from "@main/components/toolbar/ToolbarTheme.vue";
import ToolbarSearchBar from "@main/components/toolbar/ToolbarSearchBar.vue";
import ToolbarNotifications from "@main/components/toolbar/ToolbarNotifications.vue";

interface MenuItem {
  key: string;
  text: string;
  link: string;
  group: string;
}

export default defineComponent({
  name: "SubscriptionLayout",
  components: {
    ToolbarNotifications,
    MainMenu,
    ToolbarUser,
    ToolbarApps,
    ToolbarTheme,
    ToolbarSearchBar,
  },
  setup() {
    const { navigateTo, navigateToName, routeName } = useRouteComponents();
    const drawer = ref<boolean | null>(null);
    const showSearch = ref<boolean>(false);
    let subscriptionNavigation = config.subscriptionsNavigation;
    const navigation = config.navigation;
    const product = config.product;
    const isContentBoxed = theme.isContentBoxed;
    const menuTheme = theme.menuTheme;
    const isToolbarDetached = theme.isToolbarDetached;
    const toolbarTheme = theme.toolbarTheme;
    const dashboard = [
      {
        key: "Dashboard",
        icon: "$home",
        text: "Dashboard",
        link: "/subscriptions/dashboard",
        group: "/dashboard",
      },
    ];
    const store = useStore();
    const isDarkTheme = computed<boolean>(
      () => store.getters[UIGetters.IS_DARK_THEME]
    );

    return {
      dashboard,
      drawer,
      showSearch,
      subscriptionNavigation,
      navigation,
      product,
      isContentBoxed,
      menuTheme,
      isToolbarDetached,
      toolbarTheme,
      navigateTo,
      navigateToName,
      isDarkTheme,
      routeName,
    };
  },
});
</script>
