<template>
  <div>
    <!-- Navigation -->
    <v-navigation-drawer
        v-model="drawer"
        app
        floating
        class="elevation-1"
        :right="$vuetify.rtl"
        :light="menuTheme === 'light'"
        :dark="menuTheme === 'dark'"
    >
      <!-- Navigation menu info -->
      <template v-slot:prepend>
        <div class="pa-2 pb-0">
          <div class="title font-weight-bold text-uppercase primary--text">
            {{ product.name }}
          </div>
          <div class="overline grey--text">{{ product.version }}</div>
        </div>
      </template>
      <main-menu :menu="navigation.menu"/>
      <main-sub-menu v-for="sideNav in sideNavs" :title="sideNav.title" :menu="sideNav.menu"
                     :defaultExpanded="sideNav.defaultExpanded" :classProps="sideNav.classProps"
                     :defaultSubMenuExpanded="sideNav.defaultSubMenuExpanded"
                     :key="sideNav.key">
        <template v-slot:statuses v-if="sideNav.title === 'Selected Patient'">
          <statuses v-if="(selectedPatientListingDetails || {}).patientId" :loading="false"
                    :linkInformation="(selectedPatientListingDetails || {}).linkInformation"
                    :patientId="(selectedPatientListingDetails || {}).patientId"
                    :subscription="subscription" :key="JSON.stringify((selectedPatientListingDetails || {}))"
                    :class="$vuetify.breakpoint.lgOnly ? 'px-0' : 'px-1'"
                    isSideNav
                    class="my-1"
          />
        </template>
      </main-sub-menu>

    </v-navigation-drawer>
    <!-- Toolbar -->
    <v-app-bar
        app
        :color="isToolbarDetached ? 'surface' : undefined"
        :flat="isToolbarDetached"
        :light="toolbarTheme === 'light'"
        :dark="toolbarTheme === 'dark'"
    >
      <v-card
          class="flex-grow-1 d-flex"
          :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']"
          :flat="!isToolbarDetached"
      >
        <div class="d-flex flex-grow-1 align-center">

          <!-- search input mobile -->
          <v-container fluid class="pa-0" v-if="showSearch">
            <toolbar-search-bar mobile :showSearch.sync="showSearch"/>
          </v-container>

          <div v-else class="d-flex flex-grow-1 align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-app-bar-nav-icon text
                                    @click.stop="drawer = !drawer"
                                    v-bind="attrs"
                                    v-on="on"
                ></v-app-bar-nav-icon>
              </template>
              <span>Navigation Menu</span>
            </v-tooltip>
            <toolbar-shortcuts></toolbar-shortcuts>
            <v-spacer class="d-none d-lg-block"></v-spacer>

            <!-- search input desktop -->
            <toolbar-search-bar/>
            <v-spacer class="d-block d-sm-none"></v-spacer>
            <!-- search input mobile -->
            <v-btn class="d-block d-sm-none" icon @click="showSearch = true">
              <v-icon>$search</v-icon>
            </v-btn>

            <toolbar-apps/>


            <toolbar-theme :isDarkTheme="isDarkTheme"/>
            <toolbar-help/>
            <toolbar-notifications/>
            <toolbar-user/>
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-main>
      <patient-filter-banner
          v-show="canViewPatients && (routeName === 'patients' || routeName.includes('dashboard'))"/>
      <v-container
          :fluid="!isContentBoxed"
          :class="((routeName.includes('patients-tx-plan-payment-options') || routeName.includes('patients-tx-plan-tx-start-docs')) && $vuetify.breakpoint.width < 1750) || $vuetify.breakpoint.mobile ?  'mt-1 px-0 py-2': ''"
      >
        <v-layout>
          <slot></slot>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script lang="ts">
// navigation menu configurations
import config from "../../main/src/configs";

import MainMenu from "../../main/src/components/navigation/MainMenu.vue";
import ToolbarUser from "../../main/src/components/toolbar/ToolbarUser.vue";
import ToolbarApps from "../../main/src/components/toolbar/ToolbarApps.vue";
import theme from "../../main/src/configs/theme";
import {computed, defineComponent, ref, watch} from "vue";
import {SessionGetters} from "../store/session";
import {usePatientService, useStore} from "../providers";
import {UIGetters} from "../store/ui-customizations";
import ToolbarTheme from "../../main/src/components/toolbar/ToolbarTheme.vue";
import {useRouteComponents} from "../../main/src/composables/helpers";
import ToolbarSearchBar from "../../main/src/components/toolbar/ToolbarSearchBar.vue";
import dashboardNavigationMenu from "../../main/src/configs/menus/dashboard.menu";
import {PatientsActions, PatientsGetters, PatientsMutations} from "../store/patients";
import PatientFilterBanner from "../../main/src/components/banners/PatientFilterBanner.vue";
import {PatientTreatmentPlansGetters} from "../store/treatment-plans";
import {cloneDeep, debounce} from "lodash";
import {PatientFormsAndSignaturesGetters} from "../store/patients/patient-forms-and-signatures";
import {PatientContractsGetters, PatientContractsMutations} from "../store/documents/patient-contracts";
import {PatientContract} from "../store/documents/patient-contracts/interfaces";
import {
  MainSubMenu as IMainSubMenu,
  NavMenu,
  NavMenuItem,
  Patient,
  PatientDataSearchParams,
  TreatmentPlan
} from "../store/interfaces";
import MainSubMenu from "../../main/src/components/navigation/MainSubMenu.vue";
import {ListPatient} from "@shared/store/patients/interfaces";
import {ClientSubscription} from "@shared/store/financials/interfaces";
import {ClientSubscriptionsGetters} from "@shared/store/financials/client-subscriptions";
import Statuses from "@main/components/forms/patients/status/Statuses.vue";
import {AnytimeFormsGetters} from "@shared/store/documents/anytime-documents";
import {PatientIntakeFormsGetters} from "@shared/store/documents/patient-intake-documents";
import ToolbarShortcuts from "@main/components/toolbar/ToolbarShortcuts.vue";
import ToolbarNotifications from "@main/components/toolbar/ToolbarNotifications.vue";
import ToolbarHelp from "@main/components/toolbar/ToolbarHelp.vue";

export default defineComponent({
  name: "DefaultLayout",
  components: {
    PatientFilterBanner,
    MainMenu,
    MainSubMenu,
    ToolbarUser,
    ToolbarApps,
    ToolbarTheme,
    ToolbarSearchBar,
    ToolbarShortcuts,
    ToolbarNotifications,
    Statuses,
    ToolbarHelp,
  },
  setup() {
    const {navigateTo, routeName} = useRouteComponents();
    const drawer = ref<boolean | null>(null);
    const showSearch = ref<boolean>(false);
    let navigation = config.navigation;
    const product = config.product;
    const isContentBoxed = theme.isContentBoxed;
    const menuTheme = theme.menuTheme;
    const isToolbarDetached = theme.isToolbarDetached;
    const toolbarTheme = theme.toolbarTheme;
    const search = ref<HTMLElement | null>(null);
    const store = useStore();
    const patientsService = usePatientService();
    const canViewPatients = computed<boolean>(
        () => store.getters[SessionGetters.CAN_VIEW_PATIENTS]
    );
    const isDarkTheme = computed<boolean>(
        () => store.getters[UIGetters.IS_DARK_THEME]
    );
    const selectedPatientSideNav = computed<NavMenu[] | undefined>(
        () => store.getters[PatientsGetters.SELECTED_PATIENT_SIDE_NAV]
    );
    const patientContracts = computed<PatientContract[] | undefined>(
        () => store.getters[PatientContractsGetters.PATIENT_CONTRACTS]
    );
    const patientDataFilters = computed<PatientDataSearchParams>(
        () => store.getters[PatientsGetters.PATIENT_DATA_FILTERS]
    );
    const contractsSideNav = computed<NavMenu | undefined>(
        () => store.getters[PatientContractsGetters.CONTRACTS_SIDE_NAV]
    );
    const subscription = computed<ClientSubscription>(
        () => store.getters[ClientSubscriptionsGetters.CLIENT_SUBSCRIPTIONS]
    );
    const selectedPatient = computed<Patient | undefined>(
        () => store.getters[PatientsGetters.SELECTED_PATIENT]
    );
    const selectedPatientTreatmentPlan = computed<TreatmentPlan | undefined>(
        () => store.getters[PatientTreatmentPlansGetters.SELECTED_PATIENT_TREATMENT_PLAN]
    );
    const selectedPatientListingDetails = computed<ListPatient | undefined>(
        () => (store.getters[PatientsGetters.PATIENTS] || [])?.find((patient: Patient) => patient.patientId === selectedPatient.value?.patientId) || undefined
    );
    const selectedPatientTxPlansSideNav = computed<NavMenu | undefined>(
        () => {
          const txPlans = cloneDeep(store.getters[PatientTreatmentPlansGetters.SELECTED_PATIENT_TREATMENT_PLANS_SIDE_NAV]);
          txPlans?.items?.[0].items?.forEach((navMenuItem: NavMenuItem, index: number) => {
            const splitLinkTxPlanId = navMenuItem.link.split('/')?.pop()?.toLowerCase();
            if (splitLinkTxPlanId?.toLowerCase() !== 'create' && txPlans?.items[0].items?.[index] && selectedPatientListingDetails?.value?.linkInformation?.txStartEsignDocumentInformation?.length) {
              txPlans.items[0].items[index].subTitle2 = '';
              for (let i = 0; i < selectedPatientListingDetails.value?.linkInformation?.txStartEsignDocumentInformation?.length; i = i + 1) {
                const txStartEsignDocumentInformation = selectedPatientListingDetails.value?.linkInformation?.txStartEsignDocumentInformation?.[i];
                const docInfoByTxPlanId = txStartEsignDocumentInformation?.patientTreatmentPlanId?.toLowerCase() === splitLinkTxPlanId ? txStartEsignDocumentInformation : undefined
                if (docInfoByTxPlanId) {
                  txPlans.items[0].items[index].subTitle2 += txPlans.items[0].items[index].subTitle2?.length ? ', ' : '';
                  switch (docInfoByTxPlanId?.status) {
                    case 0: {
                      txPlans.items[0].items[index].subTitle2 += '  * Awaiting E-Signature';
                      break;
                    }
                    case 1: {
                      txPlans.items[0].items[index].subTitle2 += '  * Partially E-Signed';
                      break;
                    }
                    case 2: {
                      txPlans.items[0].items[index].subTitle2 += '  * E-Signed';
                      break;
                    }
                    case 3: {
                      txPlans.items[0].items[index].subTitle2 += '  * Signed (wet)';
                      break;
                    }
                  }
                }
              }
            }
          });
          return txPlans;
        }
    );
    const patientHistorySideNav = computed<NavMenu | undefined>(
        () => store.getters[PatientFormsAndSignaturesGetters.PATIENT_HISTORY_SIDE_NAV]
    );

    const sideNavs = computed<IMainSubMenu[] | undefined>(
        () => {
          let sideNavs = [] as IMainSubMenu[];
          if (canViewPatients.value && selectedPatientSideNav.value) { //can view patients
            const navMenu = cloneDeep(selectedPatientSideNav.value) || [];
            if (selectedPatient.value && selectedPatient.value?.patientResponsibleParties?.length && selectedPatientTxPlansSideNav.value) { //tx plans
              navMenu.push(selectedPatientTxPlansSideNav.value as NavMenu);
            }
            sideNavs.push(
                {
                  classProps: undefined,
                  title: "Selected Patient",
                  menu: navMenu,
                  defaultExpanded: !((routeName.value.includes("payment-options") || routeName.value.includes("tx-start-docs") || routeName.value.includes("patients-history"))),
                  defaultSubMenuExpanded: !((routeName.value.includes("payment-options") || routeName.value.includes("tx-start-docs") || routeName.value.includes("patients-history"))),
                  key: 'selected-patient-tx-plan-sub-menu-' + selectedPatient.value?.patientId + '-' + selectedPatientTreatmentPlan.value?.patientTreatmentPlanId
                });
            if (contractsSideNav.value && Array.isArray(contractsSideNav.value) && contractsSideNav.value.length) {
              sideNavs.push(contractsSideNav.value[0]);
              if (contractsSideNav.value.length >= 2) {
                sideNavs.push(contractsSideNav.value[1]);
              }
            }
            if (patientHistorySideNav.value) {
              sideNavs.push(
                  {
                    classProps: "mt-n1",
                    title: selectedPatient ? 'Signing Documents' : '',
                    menu: [cloneDeep(patientHistorySideNav.value)],
                    defaultExpanded: true,
                    defaultSubMenuExpanded: true,
                    key: 'signing-documents-sub-menu-' + selectedPatient.value?.patientId
                  } as IMainSubMenu);
            }
          }
          return sideNavs;
        }
    );

    if (!canViewPatients.value) {
      navigation.menu.forEach((item, index) => {
        navigation.menu[index].items = item.items.filter(
            (item) => item.text !== "Patients"
        );
      });
    }

    const selectedPatientUpdatesLoading = computed<boolean>(
        () => store.getters[AnytimeFormsGetters.LOADING] || store.getters[PatientIntakeFormsGetters.LOADING]
            || store.getters[PatientFormsAndSignaturesGetters.LOADING] || store.getters[PatientTreatmentPlansGetters.LOADING]
            || store.getters[PatientsGetters.LOADING] || store.getters[PatientContractsGetters.LOADING]
    );

    const updateSelectedPatient = debounce(async () => {
      store.commit(PatientsMutations.SET_SELECTED_PATIENT_UPDATED, false);
      await store.dispatch(PatientsActions.LIST_PATIENTS, {
        service: patientsService,
        patientDataSearchParams: patientDataFilters.value ? patientDataFilters.value : undefined
      });
    }, 200, {maxWait: 3000});

    watch(
        () => selectedPatientUpdatesLoading.value,
        async () => {
          if (!selectedPatientUpdatesLoading.value && store.getters[PatientsGetters.SELECTED_PATIENT_UPDATED] && !!selectedPatient.value) {
            updateSelectedPatient();
          }
        });


    watch(
        () => [selectedPatientTreatmentPlan.value, patientContracts.value],
        async () => {
          if (patientContracts.value && patientContracts.value?.length && selectedPatientTreatmentPlan.value) {
            await store.commit(PatientContractsMutations.SET_PATIENT_CONTRACTS_SIDE_NAV, {
              patientContracts: patientContracts.value,
              treatmentPlan: selectedPatientTreatmentPlan.value
            });
          } else {
            await store.commit(PatientContractsMutations.SET_PATIENT_CONTRACTS_SIDE_NAV, {
              patientContracts: undefined,
              treatmentPlan: undefined
            });
          }
        });

    return {
      drawer,
      showSearch,
      dashboardNavigationMenu,
      navigation,
      product,
      isContentBoxed,
      menuTheme,
      isToolbarDetached,
      toolbarTheme,
      search,
      isDarkTheme,
      navigateTo,
      routeName,
      sideNavs,
      canViewPatients,
      selectedPatient,
      selectedPatientTreatmentPlan,
      selectedPatientTxPlansSideNav,
      selectedPatientSideNav,
      patientContracts,
      selectedPatientListingDetails,
      subscription,
      selectedPatientUpdatesLoading,
    };
  },
});
</script>