import Vue from 'vue';

Vue.directive('required-asterisk', {
    bind: function (el, binding, vnode) {
        const rules = binding.value;
        if (Array.isArray(rules) && rules?.some((rule) => rule?.toString() === 'value => !!value || value === 0 || message' || rule?.toString() === '(value) => value.length > 0 || message')) {
            const label = el.querySelector('.v-label');
            if (label) {
                const asterisk = document.createElement('span');
                asterisk.textContent = ' *';
                asterisk.style.color = 'red';
                label.appendChild(asterisk);
            }
        }
    },
});