import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import Vuetify from "vuetify/lib";
import config from "@main/configs";
import Vue from "vue";
//TODO this could be reduced down to just the components we need for tiptap editor
import '@fortawesome/fontawesome-free/css/all.css' // this is for tiptap
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'

//https://github.com/iliyaZelenko/tiptap-vuetify?tab=readme-ov-file#props
// Example: https://github.com/koumoul-dev/vuetify-jsonschema-form/blob/master/doc/components/wrappers/v-jsf-tiptap.vue
const vuetify = new Vuetify({
  rtl: config.theme.isRTL,
  theme: {
    dark: config.theme.globalTheme === 'dark',
    options: {
      customProperties: true
    },
    themes: {
      dark: config.theme.dark,
      light: config.theme.light
    }
  },
  icons: {
    iconfont: 'fa',
  },
});

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'fa'
});
