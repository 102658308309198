<template>
  <div>
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      class="elevation-1"
      :right="$vuetify.rtl"
      :light="menuTheme === 'light'"
      :dark="menuTheme === 'dark'"
    >
      <!-- Navigation menu info -->
      <template v-slot:prepend>
        <div class="pa-2 pb-0">
          <div class="title font-weight-bold text-uppercase primary--text">
            {{ product.name }}
          </div>
          <div class="overline grey--text">{{ product.version }}</div>
        </div>
      </template>

      <!-- Navigation menu -->
      <main-menu
        :show-dashboard="false"
        :menu="insVerificationNavigation.menu"
      />

      <!-- Navigation menu footer -->
      <template v-slot:append>
        <!-- Footer navigation links -->
        <div class="pa-1 text-center">
          <v-btn
            v-for="(item, index) in navigation.footer"
            :key="index"
            @click="navigateTo(item.link)"
            small
            text
          >
            {{ item.key ? item.key : item.text }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar
      app
      :color="isToolbarDetached ? 'surface' : undefined"
      :flat="isToolbarDetached"
      :light="toolbarTheme === 'light'"
      :dark="toolbarTheme === 'dark'"
    >
      <v-card
        class="flex-grow-1 d-flex"
        :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']"
        :flat="!isToolbarDetached"
      >
        <div class="d-flex flex-grow-1 align-center">
          <div class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon text
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>

            <v-spacer class="d-none d-lg-block"></v-spacer>

            <toolbar-apps />

            <toolbar-theme :isDarkTheme="isDarkTheme" />

<!--            <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">-->
<!--              <toolbar-notifications />-->
<!--            </div>-->

            <toolbar-user />
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-main>
      <v-container
        class="fill-height"
        :fluid="!isContentBoxed"
        :class="{ 'mt-1 pa-0 py-2': $vuetify.breakpoint.mobile }"
      >
        <v-layout>
          <slot></slot>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script lang="ts">
// navigation menu configurations
import config from "../../main/src/configs";
import MainMenu from "../../main/src/components/navigation/MainMenu.vue";
import ToolbarUser from "../../main/src/components/toolbar/ToolbarUser.vue";
import ToolbarApps from "../../main/src/components/toolbar/ToolbarApps.vue";
import theme from "../../main/src/configs/theme";
import { computed, defineComponent, ref } from "vue";
import {useRouteComponents} from "../../main/src/composables/helpers";
import {useStore} from "../providers";
import {UIGetters} from "../store/ui-customizations";
import ToolbarTheme from "../../main/src/components/toolbar/ToolbarTheme.vue";

export default defineComponent({
  name: "InsuranceVerificationLayout",
  components: {
    MainMenu,
    ToolbarUser,
    ToolbarApps,
    ToolbarTheme,
  },
  setup() {
    const { navigateTo, navigateToName, routeName } = useRouteComponents();
    const drawer = ref<boolean | null>(null);
    const showSearch = ref<boolean>(false);
    const insVerificationNavigation = config.insuranceVerificationNavigation;
    const navigation = config.navigation;
    const product = config.product;
    const isContentBoxed = theme.isContentBoxed;
    const menuTheme = theme.menuTheme;
    const isToolbarDetached = theme.isToolbarDetached;
    const toolbarTheme = theme.toolbarTheme;
    const store = useStore();
    const isDarkTheme = computed<boolean>(
      () => store.getters[UIGetters.IS_DARK_THEME]
    );

    return {
      drawer,
      showSearch,
      insVerificationNavigation,
      navigation,
      product,
      isContentBoxed,
      menuTheme,
      isToolbarDetached,
      toolbarTheme,
      navigateTo,
      navigateToName,
      isDarkTheme,
      routeName,
    };
  },
});
</script>
