import moment from "moment/moment";

const dateRegEx = new RegExp(
    /(^\d{1,4}[.,\/-]\d{1,2}[.,\/-]\d{1,4}.*$)/
);
export function displayFilter(item: any, queryText: string, itemText: string) {
    queryText = queryText?.trim()?.replace(/(\s\s)+/gi, ' ')?.toLowerCase();

    itemText = itemText?.trim()?.replace("- DOB:", "")?.replace("- ID:", "")?.toLowerCase();
    if (queryText?.length >= 1) {
        if (itemText?.includes(queryText)) {
            return true;
        }

        const patient = item?.patient;
        const queryTextDate = queryText?.length >= 6 && dateRegEx.test(queryText) && moment.utc(queryText).isValid() ? moment.utc(queryText)?.format('YYYY-MM-DD') : '';
        queryText = queryTextDate ? queryText?.substr(0, 100) : queryText?.replace(/[^[\w\s\-,\.\'']/gi, '');
        const queryTextIncludesSpace = /\s/.test(queryText);
        const queryTextWords = queryTextIncludesSpace ? queryText?.split(" ") : [];
        return !!(patient
            ? queryTextDate?.length
                ? (patient?.birthDate?.substr(0, 10) === queryTextDate)
                : queryTextWords?.length >= 2 ?
                    ((patient?.lastName && (patient?.lastName?.toLowerCase()?.startsWith(queryTextWords?.[0])
                                || (queryTextWords?.[1] && patient?.lastName?.toLowerCase()?.startsWith(queryTextWords?.[1]))
                                || (queryTextWords?.[2] && patient?.lastName?.toLowerCase()?.startsWith(queryTextWords?.[2])))
                        )
                        && (patient?.firstName && (patient?.firstName?.toLowerCase()?.startsWith(queryTextWords?.[0])
                                || (queryTextWords?.[1] && patient?.firstName?.toLowerCase()?.startsWith(queryTextWords?.[1]))
                                || (queryTextWords?.[2] && patient?.firstName?.toLowerCase()?.startsWith(queryTextWords?.[2])))
                        ))
                    || (patient?.externalPatientId && (patient?.externalPatientId.toLowerCase()?.includes(queryText)))
                    : (
                        (patient?.lastName && (patient?.lastName?.toLowerCase()?.includes(queryText)))
                        || (patient?.firstName && (patient?.firstName?.toLowerCase()?.includes(queryText)))
                        || (patient?.externalPatientId && (patient?.externalPatientId.toLowerCase()?.includes(queryText)))
                    )
            : !queryTextDate?.length ?
                (itemText && itemText?.toLowerCase()?.includes(queryText) || queryText?.includes(itemText?.toLowerCase()))
                : false);
    }
    return false;
}