import {usePatientService, usePatientTreatmentPlansService, useStore} from "@shared/providers";
import {computed} from "vue";
import {PatientsActions} from "@shared/store/patients";
import moment from "moment";
import {ClientSubscriptionsGetters} from "@shared/store/financials/client-subscriptions";
import {ListPatient} from "@shared/store/patients/interfaces";
import {useRouteComponents} from "@main/composables/helpers";

export function usePatientSmartRouter() {
    const store = useStore();
    const patientsService = usePatientService();
    const treatmentPlanService = usePatientTreatmentPlansService();
    const {clickNavigateToRoute, routeName, navigateToName, navigateTo} = useRouteComponents();

    const areIntakeAndAnytimeFormsEnabled = computed<boolean>(
        () => store.getters[ClientSubscriptionsGetters.ARE_INTAKE_AND_ANYTIME_FORMS_ENABLED]
    );
    const areFeePresentationsEnabled = computed<boolean>(
        () => store.getters[ClientSubscriptionsGetters.ARE_FEE_PRESENTATIONS_ENABLED]
    );

    async function routeToPatientEdit(patient: ListPatient, event?: PointerEvent) {
        if (!patient?.patientId) return;

        if (routeName.value === "patients-edit" && !event?.ctrlKey) {
            await store.dispatch(PatientsActions.GET_PATIENT_DETAIL, {
                service: patientsService,
                treatmentPlanService: treatmentPlanService,
                id: patient.patientId,
            })
            return;
        }

        if (event) {
            await clickNavigateToRoute(event, {
                name: "patients-edit",
                params: {id: patient.patientId},
                query: (typeof patient?.linkInformation?.patientTreatmentPlans?.[0]?.patientTreatmentPlanId === 'string'
                    && patient?.linkInformation?.patientTreatmentPlans?.[0]?.patientTreatmentPlanId?.length)
                    ? {treatmentPlanId: patient?.linkInformation?.patientTreatmentPlans?.[0]?.patientTreatmentPlanId}
                    : undefined
            }, true)
        } else {
            await navigateToName("patients-edit", true, {id: patient.patientId},
                (typeof patient?.linkInformation?.patientTreatmentPlans?.[0]?.patientTreatmentPlanId === 'string'
                    && patient?.linkInformation?.patientTreatmentPlans?.[0]?.patientTreatmentPlanId?.length)
                    ? {treatmentPlanId: patient?.linkInformation?.patientTreatmentPlans?.[0]?.patientTreatmentPlanId}
                    : {});
        }
    }

    async function getSmartPatientRoute(patient: ListPatient, event?: PointerEvent) {
        const patientId = patient?.patientId;
        if (!patientId) {
            return;
        }

        const linkInformation = patient?.linkInformation;
        const isPatientComplete = linkInformation?.responsiblePartyIds?.length && patient?.firstName?.length && patient?.lastName?.length;
        if (!isPatientComplete) {
            await routeToPatientEdit(patient, event);
            return;
        }

        // fee presentations enabled
        if (areFeePresentationsEnabled.value) {
            const hasTxPlans = (linkInformation?.patientTreatmentPlans || []).length > 0;
            const txPlanId = (linkInformation?.patientTreatmentPlans || [])?.[0]?.patientTreatmentPlanId;
            const latestTxPlan = (linkInformation?.patientTreatmentPlans || [])?.[0];
            if (!hasTxPlans || !txPlanId) {
                await navigateToName('patients-treatment-plans-create', true, {'id': patientId});
                return;
            }
            const latestSlidersEmailed = linkInformation?.emailedSlidersDocumentInformation?.[0];

            const slidersReadOnly = !!latestSlidersEmailed?.isReadOnly;
            const slidersAccepted = latestTxPlan?.status === 1;
            const slidersDeclined = latestTxPlan?.status === 0;
            const slidersUpdated = (latestTxPlan?.status || -1) > -1;
            const areSlidersLatestTxPlan =  latestSlidersEmailed?.createdDate
              ?  moment(latestSlidersEmailed.createdDate)?.isAfter(moment(latestTxPlan.createdDate))
              : latestTxPlan?.acceptedOrDeclinedDate
                    ? moment(latestTxPlan?.acceptedOrDeclinedDate)?.isAfter(moment(latestTxPlan.createdDate))
                : false;
            const latestTxStartDocsEmailed = linkInformation?.txStartEsignDocumentInformation?.[0];
            const txStartDocsDocumentCreated = latestTxStartDocsEmailed?.patientTreatmentPlanId === latestTxPlan?.patientTreatmentPlanId || latestTxStartDocsEmailed?.createdDate && latestTxPlan?.createdDate && moment(latestTxStartDocsEmailed.createdDate)?.isAfter(moment(latestTxPlan.createdDate));
            if (txStartDocsDocumentCreated) {
                await navigateToName('patients-history', true, {
                    'id': patientId,
                });
                return;
            }

            if (!areSlidersLatestTxPlan) {
                await navigateToName('patients-tx-plan-payment-options-basic', true, {
                    'id': patientId,
                    'treatmentPlanId': txPlanId
                });
                return;
            }

            if (slidersAccepted || slidersReadOnly) {
                await navigateToName('patients-tx-plan-tx-start-docs-basic', true, {
                    'id': patientId,
                    'treatmentPlanId': txPlanId
                });
                return;
            }

            if (slidersDeclined || !slidersUpdated) {
                await navigateToName('patients-tx-plan-payment-options-basic', true, {
                    'id': patientId,
                    'treatmentPlanId': txPlanId
                });
                return;
            }
        }

        if (areIntakeAndAnytimeFormsEnabled.value) {
            {
                const intakeFormCreated = (linkInformation?.intakeFormsDocumentsInformation || [])?.length > 0;
                const anytimeFormCreated = (linkInformation?.anytimeFormsDocumentsInformation || [])?.length > 0;
                if (intakeFormCreated || anytimeFormCreated) {
                    await navigateToName('patients-history', true, {
                        'id': patientId,
                    });
                } else {
                    await navigateToName('patients-additional-forms', true, {
                        'id': patientId,
                    });
                }
                return;
            }
        }
        //default route
        await routeToPatientEdit(patient, event);
    }

    return {
        getSmartPatientRoute,
    };
}
