<template>
  <div
      v-shortkey="['ctrl', '/']"
      @shortkey="onKeyup"
      class="d-flex flex-grow-1 align-center"
  >
    <!--    mobile-->
    <v-autocomplete
        v-if="showSearch && mobile"
        ref="searchRef"
        append-icon="$close"
        placeholder="Search"
        prepend-inner-icon="$search"
        v-model="selected"
        :items="searchItems"
        item-text="text"
        item-value="value"
        :loading="loading"
        :disabled="sessionLoading"
        :search-input.sync="searchInput"
        :hide-no-data="typeof searchInput === 'undefined' || searchInput === null || searchInput.length === 0"
        :no-data-text="!(loadingPatientList || searching) ? (searchInput || '').length >= 3 ? 'No results found' : 'No results found' : ''"
        :filter="displayFilter"
        hide-details
        solo
        flat
        autofocus
        @click:append="close(true)"
        v-debounce.1s.unlock.cancelonempty="loadResults"
        @input="onInput(selected)"
    >
      <template v-slot:append
                v-if="loading || (searching && (typeof searchInput === 'string' && searchInput.length > 0))">
        <div class="text-center">
          <v-progress-circular
              color="primary"
              indeterminate
              :width="2"
              :size="16"
          ></v-progress-circular>
        </div>
      </template>
      <template v-slot:append-item>
        <v-list-item-content
            v-show="searching || enableLoadMoreResults || loadingPatientList || (!searching && !enableLoadMoreResults && !loadingPatientList && searchInput && searchInput.length < 3)">
          <v-list-item-title
              v-if="!searching && !enableLoadMoreResults && !loadingPatientList && searchInput && searchInput.length < 3"
              class="ml-2 primary--text">
            <v-icon small class="mr-1 primary--text">$info</v-icon>
            Enhanced search requires at least 3 characters
          </v-list-item-title>
          <v-list-item-title
              v-else-if="searching || (!searching && enableLoadMoreResults && !loadingPatientList && canViewPatients)"
              class="ml-2 primary--text" :disabled="true">
            <v-progress-circular
                :size="10"
                :width="1"
                color="primary"
                indeterminate
            ></v-progress-circular>
            Loading More Results
          </v-list-item-title>
          <v-list-item-title v-else-if="loadingPatientList" class="ml-2 primary--text" :disabled="true">
            <v-progress-circular
                :size="10"
                :width="1"
                color="primary"
                indeterminate
            ></v-progress-circular>
            Loading Patient List
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content class="py-1">
            <v-list-item-title v-text="data.item.text"></v-list-item-title>
            <v-list-item-subtitle v-text="data.item.group"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="$vuetify.breakpoint.width > 700 && data.item.group === 'Patient'"
                              class="ma-0">
            <statuses v-if="(data.item || {}).value" :loading="loading"
                      :linkInformation="((data.item || {}).patient || {}).linkInformation"
                      :patientId="(data.item || {}).value"
                      :subscription="subscription" :key="JSON.stringify(((data.item || {}).patient))"
                      :class="$vuetify.breakpoint.lgOnly ? 'px-0' : 'px-1'"
                      class="my-1"
                      isSearch
                      @close="close(true)"

            />
          </v-list-item-action>
          <status-actions v-if="(data.item || {}).value && data.item.group === 'Patient'" :loading="loading"
                          :linkInformation="((data.item || {}).patient || {}).linkInformation"
                          :patientId="(data.item || {}).value"
                          :patient="((data.item || {}).patient || {})"
                          :subscription="subscription" :key="JSON.stringify(((data.item || {}).patient))"
                          :class="$vuetify.breakpoint.lgOnly ? 'px-0' : 'px-0'"
                          class="ma-0"
                          isSearch
                          @close="close(true)"
          />
        </template>
      </template>
    </v-autocomplete>
    <!--desktop-->
    <v-autocomplete
        v-else
        ref="searchRef"
        append-icon=""
        v-model="selected"
        class="mx-1 hidden-xs-only"
        placeholder="Search (shortcut: ctrl+ / )  Ex. Name, DOB, ID"
        prepend-inner-icon="$search"
        :search-input.sync="searchInput"
        :items="searchItems"
        item-text="text"
        item-value="value"
        :disabled="sessionLoading"
        filled
        :filter="displayFilter"
        hide-details
        :hide-no-data="typeof searchInput === 'undefined' || searchInput === null || searchInput.length === 0"
        :no-data-text="!(loadingPatientList || searching) ? (searchInput || '').length >= 3 ? 'No results found' : 'No results found' : ''"
        rounded
        dense
        clear-icon="$close"
        :clearable="!loading"
        @click:clear="searchInput = null"
        v-debounce.1s.unlock.cancelonempty="loadResults"
        @input="onInput(selected)"
    > <!--  TODO INPUT - handle navigation to patient --><!--             -->
      <template v-slot:append
                v-if="loading || (searching && (typeof searchInput === 'string' && (searchInput || '').length > 0))">
        <div class="text-center">
          <v-progress-circular
              color="primary"
              indeterminate
              :width="2"
              :size="16"
          ></v-progress-circular>
        </div>
      </template>
      <template v-slot:append-item>
        <v-list-item-content
            v-show="searching || enableLoadMoreResults || loadingPatientList || (!searching && !enableLoadMoreResults && !loadingPatientList && searchInput && searchInput.length < 3)">
          <v-list-item-title
              v-if="!searching && !enableLoadMoreResults && !loadingPatientList && searchInput && searchInput.length < 3"
              class="ml-2 primary--text">
            <v-icon small class="mr-1 primary--text">$info</v-icon>
            Enhanced search requires at least 3 characters
          </v-list-item-title>
          <v-list-item-title
              v-else-if="searching || (!searching && enableLoadMoreResults && !loadingPatientList && canViewPatients)"
              class="ml-2 primary--text" :disabled="true">
            <v-progress-circular
                :size="10"
                :width="1"
                color="primary"
                indeterminate
            ></v-progress-circular>
            Loading More Results
          </v-list-item-title>
          <v-list-item-title v-else-if="loadingPatientList" class="ml-2 primary--text" :disabled="true">`
            <v-progress-circular
                :size="10"
                :width="1"
                color="primary"
                indeterminate
            ></v-progress-circular>
            Loading Patient List
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content class="py-1 d-flex justify-lg-space-between">
            <v-list-item-title v-text="data.item.text"></v-list-item-title>
            <v-list-item-subtitle v-text="data.item.group"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="$vuetify.breakpoint.width > 700 && data.item.group === 'Patient'"
                              class="ma-0">
            <statuses v-if="(data.item || {}).value" :loading="loading"
                      :linkInformation="((data.item || {}).patient || {}).linkInformation"
                      :patientId="(data.item || {}).value"
                      :subscription="subscription" :key="JSON.stringify(((data.item || {}).patient))"
                      :class="$vuetify.breakpoint.lgOnly ? 'px-0' : 'px-1'"
                      class="my-1"
                      isSearch
                      @close="close"

            />
          </v-list-item-action>
        </template>
        <status-actions v-if="(data.item || {}).value && data.item.group === 'Patient'" :loading="loading"
                        :linkInformation="((data.item || {}).patient || {}).linkInformation"
                        :patientId="(data.item || {}).value"
                        :patient="((data.item || {}).patient || {})"
                        :subscription="subscription" :key="JSON.stringify(((data.item || {}).patient))"
                        :class="$vuetify.breakpoint.lgOnly ? 'px-0' : 'px-0'"
                        class="ma-0"
                        isSearch
                        @close="close"
        />
      </template>
    </v-autocomplete>
  </div>
</template>
<script lang="ts">
import {useRouteComponents} from "../../composables/helpers";
import {PatientDataSearchParams, Select} from "@shared/store";
import {computed, defineComponent, onBeforeMount, ref, watch} from "vue";
import ConfigRoutes from "../../router/configuration.routes";
import PatientRoutes from "../../router/patients.routes";
import UserRoutes from "../../router/users.routes";
import {usePatientService, useStore} from "@shared/providers";
import {PatientsActions, PatientsGetters} from "@shared/store/patients";
import {SessionGetters} from "@shared/store/session";
import {displayFilter} from "@shared/functions/searchFunctions";
import Statuses from "@main/components/forms/patients/status/Statuses.vue";
import {ClientSubscription} from "@shared/store/financials/interfaces";
import {ClientSubscriptionsGetters} from "@shared/store/financials/client-subscriptions";
import Vue from "vue";
import StatusActions from "@main/components/forms/patients/status/StatusActions.vue";
import {usePatientSmartRouter} from "@main/composables/patients/use-patient-smart-router";
import { Guid } from "guid-typescript";

interface Props {
  mobile?: boolean;
  showSearch?: boolean;
}

export default defineComponent({
  name: "ToolbarSearchBar",
  components: {Statuses, StatusActions},
  props: {
    mobile: Boolean,
    showSearch: Boolean,
  },
  setup(props: Props, {emit}) {
    const {navigateToName} = useRouteComponents();
    const {getSmartPatientRoute} = usePatientSmartRouter();
    const patientsService = usePatientService();
    const store = useStore();
    const searchRef = ref<HTMLElement | null>(null);
    const searchInput = ref<string | null>(null);
    const enableLoadMoreResults = ref<boolean>(false);
    const selected = ref<Select | null | undefined>(undefined);
    const allRoutes = ref<any>([]);
    const canViewPatients = computed<boolean>(
        () => store.getters[SessionGetters.CAN_VIEW_PATIENTS]
    );
    const patients = computed<any[]>(
        () => store.getters[SessionGetters.CAN_VIEW_PATIENTS] ? store.getters[PatientsGetters.DISPLAY_SEARCHED_PATIENTS] : []
    );
    const loading = computed<boolean>(
        () => store.getters[SessionGetters.LOADING] || store.getters[PatientsGetters.GETTING_LIST] || store.getters[PatientsGetters.SEARCHING]
    );
    const sessionLoading = computed<boolean>(
        () => store.getters[SessionGetters.LOADING]
    );
    const searching = computed<boolean>(
        () => store.getters[PatientsGetters.SEARCHING]
    );
    const loadingPatientDetails = computed<boolean>(
        () => store.getters[PatientsGetters.GETTING] && !store.getters[PatientsGetters.GETTING_LIST]
    );
    const loadingPatientList = computed<boolean>(
        () => store.getters[PatientsGetters.GETTING_LIST]
    );
    const isUserManger = computed<boolean>(
        () => store.getters[SessionGetters.IS_USER_MANAGER]
    );
    const subscription = computed<ClientSubscription>(
        () => store.getters[ClientSubscriptionsGetters.CLIENT_SUBSCRIPTIONS]
    );

    const isConfigManager = computed<boolean>(
        () => store.getters[SessionGetters.IS_CONFIG_MANAGER]
    );
    const patientDataFilters = computed<PatientDataSearchParams>(
        () => store.getters[PatientsGetters.PATIENT_DATA_FILTERS]
    );
    let prevSearch: string | null | undefined = null;
    const configRoutes: any = computed<any>(() =>
        isConfigManager.value
            ? ConfigRoutes.filter((route: { meta: { rank: number; }; }) => route?.meta?.rank !== -1)
                .sort((a: { meta: { rank: number; }; }, b: { meta: { rank: number; }; }) => {
                  return (a.meta?.rank as number) - (b.meta?.rank as number);
                })
                .map((item: { meta: { title: any; }; name: any; }) => {
                  return {text: item.meta?.title || item.name, value: item.name, group: 'Navigation - Configuration'};
                })
            : []
    );

    const userRoutes: any = computed<any>(() =>
        isUserManger.value
            ? UserRoutes.filter((route: { meta: { rank: number; }; }) => route?.meta?.rank !== -1)
                .sort((a: { meta: { rank: number; }; }, b: { meta: { rank: number; }; }) => {
                  return (a.meta?.rank as number) - (b.meta?.rank as number);
                })
                .map((item: { meta: { title: any; }; name: any; }) => {
                  return {text: item.meta?.title || item.name, value: item.name, group: 'Navigation - Users'};
                })
            : []
    );

    const patientRoutes: any = computed<any>(() =>
        canViewPatients.value
            ? PatientRoutes.filter((route: { meta: { rank: number; }; }) => route?.meta?.rank !== -1)
                .sort((a: { meta: { rank: number; }; }, b: { meta: { rank: number; }; }) => {
                  return (a.meta?.rank as number) - (b.meta?.rank as number);
                })
                .map((item: { meta: { title: any; }; name: any; }) => {
                  return {text: item.meta?.title || item.name, value: item.name, group: 'Navigation - Patients'};
                })
            : []
    );

    allRoutes.value = [...patientRoutes.value, ...configRoutes.value, ...userRoutes.value];

    const searchItems = computed<Select[]>(() => [...patients.value, ...allRoutes.value]);

    function close(isMobile: boolean = false) {
      if (isMobile) emit("update:showSearch", false);
      searchInput.value = null;
      selected.value = null;
      prevSearch = null;
      searchRef.value?.blur();
    }

    function onKeyup() {
      if (searchRef.value) {
        searchRef.value?.focus();
      }
    }

    async function loadResults(value: string | null | undefined, event: any) {
      enableLoadMoreResults.value = false;
      if (value === prevSearch) {
        return;
      }
      prevSearch = value;
      if (canViewPatients.value && (searchInput?.value || "")?.length >= 3 && !searching.value) {
        await store.dispatch(PatientsActions.SEARCH_PATIENTS, {
          service: patientsService,
          search: searchInput.value
        });
      }
    }

    async function onInput(searchedValue: any) {
      let input: any = "";
      if (searchedValue) {
        //Ex. 7721ef43-1e94-eb11-85aa-00155d2c5ac3
        if (Guid.isGuid(searchedValue)) {
          const patient = patients.value?.find((patient: any) => patient?.value === searchedValue);
          await getSmartPatientRoute(patient?.patient);
        } else {
          typeof searchedValue === 'string' ? await navigateToName(searchedValue) : await navigateToName(searchedValue?.name, true, searchedValue?.params, searchedValue?.query); //await navigateToName("patients-edit", true, {id: searchedValue})
        }
        searchInput.value = null;
        selected.value = null;
        prevSearch = null;
        searchRef.value?.blur();
        await Vue.nextTick();
        enableLoadMoreResults.value = false;
        if (props?.mobile) {
          emit("update:showSearch", false);
          searchRef.value?.blur();
        }
      }
      return input;
    }

    async function archivePatient(id?: string) {
      if (!id) return;
      await store.dispatch(PatientsActions.BULK_ARCHIVE, {
        ids: [id] as string[],
        service: patientsService,
      });
    }

    async function restorePatient(id?: string) {
      if (!id) return;
      await store.dispatch(PatientsActions.BULK_RESTORE, {
        ids: [id] as string[],
        service: patientsService,
      });
    }

    async function deletePatient(id?: string) {
      if (!id) return;
      await store.dispatch(PatientsActions.BULK_DELETE, {
        ids: [id] as string[],
        service: patientsService,
      });
    }

    watch(
        searchInput,
        (newVal) => {
          if (newVal && newVal.length >= 3) {
            enableLoadMoreResults.value = true;
          } else {
            enableLoadMoreResults.value = false;
          }
        },
        {immediate: true}
    );

    onBeforeMount(async () => {
      if ((patients.value || []).length === 0 && canViewPatients.value && !loadingPatientList.value) {
        await store.dispatch(PatientsActions.LIST_PATIENTS, {
          service: patientsService,
          patientDataSearchParams: patientDataFilters.value ? patientDataFilters.value : undefined
        });
      }
    });

    return {
      loading,
      searchItems,
      searchRef,
      selected,
      navigateToName,
      onKeyup,
      onInput,
      loadResults,
      searchInput,
      close,
      enableLoadMoreResults,
      displayFilter,
      loadingPatientDetails,
      searching,
      loadingPatientList,
      sessionLoading,
      canViewPatients,
      subscription,
      archivePatient,
      restorePatient,
      deletePatient
    };
  },
});
</script>
<style scoped>
.v-progress-circular {
  margin: .25rem;
}
</style>
