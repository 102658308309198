import {computed} from "vue";
import {PatientDataSearchParams} from "@shared/store";
import {PatientsGetters, PatientsMutations} from "@shared/store/patients";
import {useStore} from "@shared/providers";
import moment from "moment/moment";
import {cloneDeep} from "lodash";
import { PatientStatuses } from "@shared/store/constants";

export function usePatientDataFilters() {
    const store = useStore();

    const patientDataFilters = computed<PatientDataSearchParams>(
        () => store.getters[PatientsGetters.PATIENT_DATA_FILTERS]
    );

    //TODO fix bug with this not updating correctly
    const isDefaultFilter = computed<boolean>(
        () => patientDataFilters.value
        && patientDataFilters.value?.fromLastTouchedDate === moment(moment().subtract(30, 'days')).format("YYYY-MM-DD")
        && patientDataFilters.value?.patientStatus?.toLowerCase() === PatientStatuses.ACTIVE
        && (typeof patientDataFilters.value?.includeTreatmentPlans === 'undefined' || patientDataFilters?.value?.includeTreatmentPlans === -1)
        && !patientDataFilters.value?.treatmentCoordinatorIds?.length
        && !patientDataFilters.value?.doctorIds?.length
        && !patientDataFilters.value?.officeIds?.length
        && (typeof patientDataFilters.value?.statuses === 'undefined' || (patientDataFilters.value?.statuses || [])?.length === 0 || patientDataFilters.value?.statuses?.[0] === '')
    );

    async function updateFilterTypeWithValue(type: keyof PatientDataSearchParams, value: any) {
        const updatedPatientDataFilters = cloneDeep(patientDataFilters.value);
        if(updatedPatientDataFilters) {
            updatedPatientDataFilters[type] = value;
        }
        await store.commit(PatientsMutations.SET_PATIENT_DATA_FILTERS, updatedPatientDataFilters);
    }

    return {
        patientDataFilters,
        isDefaultFilter,
        updateFilterTypeWithValue,
    };
}
