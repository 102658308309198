<template>
  <v-btn icon rounded @click="setTheme" title="theme">
    <v-icon v-if="isDarkTheme">$sun</v-icon>
    <v-icon v-else>$moon</v-icon>
  </v-btn>
</template>

<script lang="ts">
import {UIActions} from "@shared/store/ui-customizations";
import {Themes} from "@shared/store/constants";
import {useStore, useUIService} from "@shared/providers";
import {defineComponent} from "vue";

interface Props {
  isDarkTheme: boolean;
}

export default defineComponent({
  name: "ToolbarTheme",
  props: {
    isDarkTheme: Boolean,
  },
  setup(props: Props) {
    const store = useStore();
    const service = useUIService();

    async function setTheme() {
      if (props.isDarkTheme) {
        await store.dispatch(UIActions.SAVE_GLOBAL_THEME, {
          theme: Themes.LIGHT,
          service,
        });
      } else {
        await store.dispatch(UIActions.SAVE_GLOBAL_THEME, {
          theme: Themes.DARK,
          service,
        });
      }
    }

    return {
      setTheme,
    };
  },
});
</script>

<style scoped lang="scss">
.theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: transparent !important;
}
</style>
