<template>
  <v-banner
      app
      :color="'surface'"
      class="lighten-1 pa-0"
      elevation="2"
      :single-line="false"
  >
    <v-chip
        v-if="patientDataFilters && (patientDataFilters['patientStatus'] || '').length > 0 && patientDataFilters['patientStatus'].toLowerCase() !== PatientStatuses.ACTIVE"
        class="mx-1"
        color="black"
        pill
        small
        dark
    >
    {{ patientDataFilters['patientStatus'] + ' patients' }}
    </v-chip>
    <v-chip
        v-if="patientDataFilters && patientDataFilters['fromLastTouchedDate']"
        class="mx-1"
        :color="isDefaultFilter ? 'grey-darken-1' : 'orange'"
        pill
        small
        dark
    >
      {{
        displayTextForDate(patientDataFilters['fromLastTouchedDate'], patientDataFilters['toLastTouchedDate'], patientDataFilters['dateRange'])
      }}
    </v-chip>
    <v-chip
        v-if="!treatmentCoordinatorsLoading && patientDataFilters && patientDataFilters['treatmentCoordinatorIds'] && patientDataFilters['treatmentCoordinatorIds'].length"
        class="mr-1"
        color="blue"
        pill
        small
        dark
        close
        @click:close="updateFilterTypeWithValue('treatmentCoordinatorIds', [])"
    >
      {{ displayTextFromSelect(patientDataFilters['treatmentCoordinatorIds'], treatmentCoordinatorsSelect) }}
    </v-chip>
    <v-chip
      v-if="!doctorsLoading && patientDataFilters && patientDataFilters['doctorIds'] && patientDataFilters['doctorIds'].length"
      class="mr-1"
      color="green"
      pill
      small
      dark
      close
      @click:close="updateFilterTypeWithValue('doctorIds', [])"
    >
      {{ displayTextFromSelect(patientDataFilters['doctorIds'], doctorsSelect) }}
    </v-chip>
    <v-chip
        v-if="!officesLoading && patientDataFilters && patientDataFilters['officeIds'] && patientDataFilters['officeIds'].length"
        class="mr-1"
        color="purple"
        pill
        small
        dark
        close
        @click:close="updateFilterTypeWithValue('officeIds', [])"
    >
      {{ displayTextFromSelect(patientDataFilters['officeIds'], officesSelect) }}
    </v-chip>
    <v-chip
        v-if="routeName !== 'dashboard' && patientDataFilters && (typeof patientDataFilters['includeTreatmentPlans'] !== 'undefined' && patientDataFilters['includeTreatmentPlans'] !== -1)"
        class="mx-1"
        color="teal"
        pill
        small
        dark
        close
        @click:close="updateFilterTypeWithValue('includeTreatmentPlans', -1)"
    >
      {{
        patientDataFilters['includeTreatmentPlans'] ? "must already have at least 1 treatment plan created" : "treatment plan doesn't exist yet"
      }}
    </v-chip>
    <v-chip
        v-if="routeName !== 'dashboard' && patientDataFilters && (patientDataFilters['statuses'] || []).length"
        class="mx-1"
        color="pink"
        pill
        small
        dark
        close
        @click:close="updateFilterTypeWithValue('statuses', undefined)"
    >
      status{{ (patientDataFilters['statuses'] || []).length > 1 ? 'es' : '' }}:
      <span>&nbsp; {{
          displayTextFromSelect((patientDataFilters['statuses'] || []), PATIENT_FILTER_STATUSES_SELECT)
        }}</span>
    </v-chip>
    <template v-slot:icon>
      <toolbar-filters :isDefaultFilter="isDefaultFilter"/>
    </template>
  </v-banner>
</template>

<script lang="ts">
import {computed, defineComponent} from "vue";
import {useStore} from "@shared/providers";
import {Select} from "@shared/store";
import {OfficesGetters} from "@shared/store/practice/offices";
import {TreatmentCoordinatorsGetters} from "@shared/store/practice/treatment-coordinators";
import {
  FILTER_TX_PLAN_OPTIONS_SELECT,
  NEW_ITEM_ID,
  PATIENT_FILTER_STATUSES_SELECT,
  PatientStatuses
} from "@shared/store/constants";
import ToolbarFilters from "../toolbar/ToolbarFilters.vue";
import {useRouteComponents} from "../../composables/helpers";
import {usePatientDataFilters} from "../../composables/helpers/use-patient-data-filters";
import {displayTextForDate} from "@shared/functions/dateFunctions";
import { DoctorsGetters } from "@shared/store/practice/doctors";

export default defineComponent({
  name: "PatientFilterBanner",
  components: {
    ToolbarFilters,
  },
  setup() {
    const store = useStore();
    const {routeName} = useRouteComponents();

    const {patientDataFilters, isDefaultFilter, updateFilterTypeWithValue} = usePatientDataFilters();
    const treatmentCoordinatorsSelect = computed<Select[]>(
        () => (store.getters[TreatmentCoordinatorsGetters.TREATMENT_COORDINATORS_WITH_EMPTY_SELECT])
    );
    const treatmentCoordinatorsLoading = computed<boolean>(
        () => store.getters[TreatmentCoordinatorsGetters.LOADING]
    );
    const doctorsSelect = computed<Select[]>(
        () => store.getters[DoctorsGetters.DOCTORS_WITH_EMPTY_SELECT]
    );
    const doctorsLoading = computed<boolean>(
      () => store.getters[DoctorsGetters.LOADING]
    );
    const officesSelect = computed<Select[]>(
        () => store.getters[OfficesGetters.OFFICES_WITH_EMPTY_SELECT]
    );
    const officesLoading = computed<boolean>(
        () => store.getters[OfficesGetters.LOADING]
    );

    function displayTextFromSelect(values: string[] = [], select: Select[]) {
      let displayText: string = "";
      if (select?.length > 0) {
        if (values?.length > 1) {
          const filteredValues = values.filter(x => x !== NEW_ITEM_ID);
          const index = select?.findIndex(x => x.value === filteredValues[0]);
          displayText = index !== -1 ? `${select[index]?.text} (+${(values.length) - 1} more)` : "N/A"
        } else {
          const index = select?.findIndex(x => x.value === values[0]);
          displayText = index !== -1 ? select[index]?.text : "N/A";
        }
      }
      return displayText;
    }

    return {
      patientDataFilters,
      officesLoading,
      treatmentCoordinatorsLoading,
      treatmentCoordinatorsSelect,
      doctorsLoading,
      doctorsSelect,
      officesSelect,
      displayTextFromSelect,
      displayTextForDate,
      FILTER_TX_PLAN_OPTIONS_SELECT,
      routeName,
      isDefaultFilter,
      updateFilterTypeWithValue,
      PATIENT_FILTER_STATUSES_SELECT,
      PatientStatuses
    };
  },
});
</script>

<style lang="scss" scoped>
.theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: transparent !important;
}

::v-deep .v-banner__content div.v-banner__icon {
  background-color: transparent !important;
  width: 48px !important;
  height: 48px !important;
}

::v-deep .v-banner__wrapper {
  padding: 8px !important;
}

</style>
