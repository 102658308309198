<template>
  <v-menu offset-y left transition="slide-y-transition" class="pr-1" :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-badge bordered :value="(unreadFilteredNotifications || []).length"
               :color="filteredNotificationsBadgeColor"
               :content="(unreadFilteredNotifications || []).length > 0 ? (unreadFilteredNotifications || []).length : undefined"
               offset-x="22" offset-y="22">
        <v-btn icon text v-on="on" title="notifications">
          <v-icon>$bell-solid</v-icon>
        </v-btn>
      </v-badge>
    </template>

    <!-- dropdown card -->
    <v-card>
      <v-list dense max-width="800" max-height="800" :width="$vuetify.breakpoint.mobile ? '80vw' : '600'"
              style="overflow-y:auto !important">
        <v-subheader class="pa-2 font-weight-bold">Notifications</v-subheader>
        <div v-for="(item, index) in filteredNotifications" :key="index">
          <v-divider v-if="index > 0 && index < filteredNotifications.length" inset></v-divider>
          <v-list-item :class="!item.isRead ? 'text--primary font-weight-bold' : 'text--secondary'">
            <v-list-item-avatar size="32" :color="item.color">

              <v-icon dark small>{{ item.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="body-1 font-weight-bold " v-text="item.title"></v-list-item-title>
              <div
                class="body-2 tiptap-vuetify-editor-display-html"
                v-sanitize.basic="item.message"
              ></div>
            </v-list-item-content>
            <v-list-item-action class="align-self-center">
              <v-list-item-action-text
              >
                {{ (item.time || moment()).fromNow() }}
              </v-list-item-action-text>
              <v-list-item-action-text class="align-content-end"
              >
                <v-btn icon
                       small
                       text
                       color="primary"
                       :title="item.isRead ? 'read' : 'unread'"
                       :loading="saving && saveType === 'read' && saveAppNotificationId === item.appNotificationId"
                       :disabled="loading"
                       @click.stop="toggleRead(item.appNotificationId)"
                >
                  <v-icon small>{{ item.isRead ? "$envelope-open-text" : "$envelope-dot" }}</v-icon>
                </v-btn>
                <v-btn icon
                       small
                       text
                       color="primary"
                       :title="item.ignoreUpdates ?'updates inactive' : 'updates active'"
                       :loading="saving && saveType === 'ignoreUpdates' && saveAppNotificationId === item.appNotificationId"
                       :disabled="loading"
                       @click.stop="toggleIgnoreUpdates(item.appNotificationId)">
                  <v-icon small>{{ item.ignoreUpdates ? "$bell-slash" : "$bell-on" }}</v-icon>
                </v-btn>
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
        </div>
        <div v-if="(filteredNotifications || []).length === 0" class="ml-2 mt-2">No notifications to display.</div>
      </v-list>

      <div class="text-center py-2 font-weight-bold">
        <v-row no-gutters justify="space-between">
          <v-col cols="8">
            <v-checkbox hide-details :input-value="hideReadNotifications"
                        @click.stop="toggleHideReadNotifications"
                        small
                        :disabled="loading"
                        class="ma-0 pa-0 ml-2 d-flex align-self-center "
                        label="Hide Read Notifications"
            />
          </v-col>
          <v-col cols="4" class="d-flex justify-end" v-if="(filteredNotifications || []).length">
            <v-btn text color="primary" outlined rounded
                   :loading="saving && saveType === 'markAllAsRead'"
                   :disabled="loading"
                   class="mr-1"
                   small
                   @click.stop="markAllAsRead">Mark All As Read
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
/*
|---------------------------------------------------------------------
| Toolbar Notifications Component
|---------------------------------------------------------------------
|
| Quickmenu to check out notifications
|
*/
import { computed, defineComponent, ref } from "vue";
import { AppNotificationRecipient } from "@shared/store/financials/interfaces";
import { useAppNotificationsService, useStore } from "@shared/providers";
import moment from "moment/moment";
import { NotificationSeverity, NotificationTypes } from "@shared/store/constants";
import { cloneDeep } from "lodash";
import { AppNotificationsActions, AppNotificationsGetters } from "@shared/store/app-notifications";

function getNotificationSeverityColor(severity?: number) {
  let color = "grey";
  if (typeof severity === "number") {
    switch (severity) {
      case NotificationSeverity.Critical:
        color = "error";
        break;
      case NotificationSeverity.Warning:
        color = "warning";
        break;
      case NotificationSeverity.Info:
        color = "primary";
        break;
      case NotificationSeverity.Success:
        color = "success";
        break;
      default:
        color = "grey";
      // code block
    }
  }
  return color;
}

function getNotificationTypeIcon(typeId?: number) {
  let icon = "$comment";
  if (typeof typeId === "number") {
    switch (typeId) {
      case NotificationTypes.Maintenance:
        icon = "$wrench";
        break;
      case NotificationTypes.Error:
        icon = "$exclamation-circle";
        break;
      case NotificationTypes.Bug:
        icon = "$bug";
        break;
      case NotificationTypes.Policy_Updates:
        icon = "$file-lines";
        break;
      case NotificationTypes.Reminders:
        icon = "$calendar-clock";
        break;
      case NotificationTypes.Feedback_Requests:
        icon = "$comment-question";
        break;
      case NotificationTypes.Events:
        icon = "$calendar-start";
        break;
      case NotificationTypes.Other:
        icon = "$comment";
        break;
      default:
        icon = "$comment";
    }
  }
  return icon;
}

export default defineComponent({
  name: "ToolbarNotifications",
  setup() {
    const store = useStore();
    const service = useAppNotificationsService();
    const saveType = ref<string>("");
    const saveAppNotificationId = ref<number>(-1);
    const notifications = computed<AppNotificationRecipient[]>(() => store.getters[AppNotificationsGetters.NOTIFICATIONS]);
    const loading = computed<boolean>(() => store.getters[AppNotificationsGetters.LOADING]);
    const saving = computed<boolean>(() => store.getters[AppNotificationsGetters.SAVING]);
    const hideReadNotifications = computed<boolean>(() => store.getters[AppNotificationsGetters.HIDE_READ_NOTIFICATIONS]);
    const filteredNotifications = computed<any[]>(() => {
      return notifications.value?.filter((notification) => {
        return (moment(notification?.appNotification?.startDate).isValid() && moment(notification?.appNotification?.startDate).isBefore(moment.now()))
          && (moment(notification?.appNotification?.endDate).isValid() && moment(notification?.appNotification?.endDate).isAfter(moment.now()))
          && (notification?.isRead === false || !hideReadNotifications.value);
      })?.map((notification) => {
        return {
          appNotificationId: notification?.appNotificationId,
          notification: notification?.appNotification,
          time: moment(notification?.appNotification?.startDate),
          color: getNotificationSeverityColor(notification?.appNotification?.severityId),
          icon: getNotificationTypeIcon(notification?.appNotification?.typeId),
          title: notification?.appNotification?.title,
          message: notification?.appNotification?.message,
          isRead: !!notification?.isRead,
          ignoreUpdates: !!notification?.ignoreUpdates
        };
      });
    }) || [];

    const filteredNotificationsBadgeColor = computed<string>(() => {
      let severityId = NotificationSeverity.Info;
      if (filteredNotifications.value?.length > 0) {
        severityId = (filteredNotifications.value || [])?.reduce((prev, current) => {
          return (prev?.notification?.severityId < current?.notification?.severityId) ? prev : current;
        })?.notification?.severityId;
      }
      return getNotificationSeverityColor(severityId);
    });

    const unreadFilteredNotifications = computed<any[]>(() => {
      return filteredNotifications.value?.filter((notification) => {
        return (notification?.isRead === false);
      });
    }) || [];

    function findNotificationById(appNotificationId: number) {
      return cloneDeep(notifications.value?.find((notification) => notification?.appNotificationId === appNotificationId));
    }

    async function toggleRead(appNotificationId: number) {
      saveAppNotificationId.value = appNotificationId;
      saveType.value = "read";
      const notification = findNotificationById(appNotificationId);
      if (notification) {
        notification.isRead = !notification.isRead;
        await updateNotification(notification);
      }
    }

    async function toggleIgnoreUpdates(appNotificationId: number) {
      saveAppNotificationId.value = appNotificationId;
      saveType.value = "ignoreUpdates";
      const notification = findNotificationById(appNotificationId);
      if (notification) {
        notification.ignoreUpdates = !notification.ignoreUpdates;
        await updateNotification(notification);
      }
    }

    async function markAllAsRead() {
      saveType.value = "markAllAsRead";
      await store.dispatch(AppNotificationsActions.MARK_ALL_NOTIFICATIONS_AS_READ, {
        service
      });
    }

    async function toggleHideReadNotifications() {
      await store.dispatch(AppNotificationsActions.SAVE_HIDE_READ_NOTIFICATIONS);
    }

    async function updateNotification(selectedNotification: AppNotificationRecipient) {
      return await store.dispatch(AppNotificationsActions.UPDATE_NOTIFICATION, {
        service,
        appNotificationRecipient: selectedNotification
      });
    }

    return {
      notifications,
      filteredNotifications,
      moment,
      markAllAsRead,
      toggleRead,
      toggleIgnoreUpdates,
      saving,
      loading,
      unreadFilteredNotifications,
      saveType,
      toggleHideReadNotifications,
      hideReadNotifications,
      saveAppNotificationId,
      filteredNotificationsBadgeColor
    };
  }
});
</script>
