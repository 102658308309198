<template>
  <v-app>
    <template v-if="loading && (authenticated || sessionStatusLoadingUser)">
      <page-loading-icon/>
    </template>
    <template v-else-if="!authenticated && !utilityErrorNoAuthOrAuthExcludingSignInPage">
        <auth-layout>
          <transition name="fade" mode="out-in">
            <signin-page></signin-page>
          </transition>
        </auth-layout>
    </template>
    <template v-else>
      <component v-if="routeMeta.cache === false" :is="currentLayout">
        <transition name="fade" mode="out-in">
          <router-view/>
        </transition>
      </component>
      <keep-alive v-else>
        <component :is="currentLayout">
          <transition name="fade" mode="out-in">
            <router-view/>
          </transition>
        </component>
      </keep-alive>
    </template>
    <idle-timeout-dialog v-if="authenticated && !utilityErrorNoAuthOrAuth"/>
    <snackbar/>
  </v-app>
</template>

<script lang="ts">
// Layouts
import DefaultLayout from "@shared/layouts/DefaultLayout.vue";
import SimpleLayout from "@shared/layouts/SimpleLayout.vue";
import AuthLayout from "@shared/layouts/AuthLayout.vue";
import AdminLayout from "@shared/layouts/AdminLayout.vue";
import ErrorLayout from "@shared/layouts/ErrorLayout.vue";
import ConfigLayout from "@shared/layouts/ConfigLayout.vue";
import InsuranceVerificationLayout from "@shared/layouts/InsuranceVerificationLayout.vue";
import SubscriptionLayout from "@shared/layouts/SubscriptionLayout.vue";
import { BroadcastChannel } from "broadcast-channel";

import Vue, { computed, defineComponent, onBeforeMount, provide, watch } from "vue";
import {
  getAllProviders,
  provideRouter,
  useAccountService,
  useAppNotificationsService,
  useClientConfigurationSettingsService,
  useClientSubscriptionService,
  useDoctorService,
  useInputFieldsService,
  useMaintenanceService,
  useOfficeService,
  useTreatmentCoordinatorsService,
  useUIService
} from "@shared/providers";
import router from "@main/router";
import store, { ClientConfiguration, RootGetters } from "@shared/store";
import { SessionActions, SessionGetters, SessionMutations } from "@shared/store/session";
import SigninPage from "@shared/pages/auth/SigninPage.vue";
import Snackbar from "./components/notifications/Snackbar.vue";
import { UIActions, UIGetters, UIMutations } from "@shared/store/ui-customizations";
import { Route } from "vue-router";
import {
  APP_LOCALSTORAGE_KEY,
  APP_SESSION_BROADCAST_CHANNEL,
  APP_UI_THEME_BROADCAST_CHANNEL,
  BROADCAST_CHANNEL_OPTIONS,
  SESSION_EXPIRY_DATE,
  SessionStatuses,
  SIGN_OUT,
  Themes
} from "@shared/store/constants";
import LoadingIcon from "./components/common/LoadingIcon.vue";
import UserLayout from "@shared/layouts/UserLayout.vue";
import PageLoadingIcon from "./components/common/PageLoadingIcon.vue";
import { ClientSubscriptionsActions, ClientSubscriptionsGetters } from "@shared/store/financials/client-subscriptions";
import IdleTimeoutDialog from "./components/dialogs/IdleTimeoutDialog.vue";
import { InputFieldsActions, InputFieldsGetters } from "@shared/store/input-fields";
import { TreatmentCoordinatorsActions } from "@shared/store/practice/treatment-coordinators";
import { OfficesActions } from "@shared/store/practice/offices";
import moment from "moment";
import Vuetify from "@shared/plugins/vuetify";
import { PatientsActions, PatientsMutations } from "@shared/store/patients";
import { usePatientDataFilters } from "@main/composables/helpers/use-patient-data-filters";
import { cloneDeep } from "lodash";
import { defaultFromDate, getDateRange, shouldCheckForMaintenanceFile } from "@shared/functions/dateFunctions";
import { MaintenanceActions, MaintenanceGetters } from "@shared/store/maintenance";
import {
  ClientConfigurationSettingsActions,
  ClientConfigurationSettingsGetters,
  ClientConfigurationSettingsMutations
} from "@shared/store/documents/client-configuration-settings";
import { useRouteComponents } from "@main/composables/helpers";
import { AppNotificationsActions } from "@shared/store/app-notifications";
import { DoctorsActions } from "@shared/store/practice/doctors";


/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
|
| In charge of choosing the layout according to the router metadata
|
*/
export default defineComponent({
  name: "App",
  components: {
    PageLoadingIcon,
    DefaultLayout,
    SimpleLayout,
    AuthLayout,
    ConfigLayout,
    InsuranceVerificationLayout,
    AdminLayout,
    UserLayout,
    ErrorLayout,
    SubscriptionLayout,
    SigninPage,
    Snackbar,
    LoadingIcon,
    IdleTimeoutDialog,
  },
  head: {},
  setup() {
    const uIService = useUIService();
    const accountService = useAccountService();
    const subscriptionService = useClientSubscriptionService();
    const inputFieldsService = useInputFieldsService();
    const treatmentCoordinatorService = useTreatmentCoordinatorsService();
    const doctorService = useDoctorService();
    const officeService = useOfficeService();
    const maintenanceService = useMaintenanceService();
    const clientConfigurationSettingsService = useClientConfigurationSettingsService();
    const appNotificationsService = useAppNotificationsService();

    const { routeMeta } = useRouteComponents();
    const authenticated = computed<boolean>(
        () => store.getters[SessionGetters.AUTHENTICATED]
    );
    const sessionLoading = computed<boolean>(
        () => store.getters[SessionGetters.LOADING]
    );
    const sessionStatus = computed<string | null>(
        () => store.getters[SessionGetters.STATUS]
    );
    const appLoading = computed<boolean>(
        () => store.getters[RootGetters.LOADING]
    );
    const subscriptionsLoading = computed<boolean>(
        () => store.getters[ClientSubscriptionsGetters.LOADING]
    );
    const inputFieldsLoading = computed<boolean>(
        () => store.getters[InputFieldsGetters.LOADING]
    );
    const loading = computed<boolean>(
        () => appLoading.value || sessionLoading.value
    );
    const clientConfigurations = computed<ClientConfiguration>(
        () => store.getters[ClientConfigurationSettingsGetters.CLIENT_CONFIGURATION]
    );

    const clientConfigurationsLastModified = computed<string | undefined>(
        () => store.getters[ClientConfigurationSettingsGetters.LAST_MODIFIED_DATE]
    );
    const sessionStatusLoadingUser = computed<boolean>(() =>
        store.getters[SessionGetters.STATUS]?.includes(
            SessionStatuses.LOADING_USER_STARTED,
            SessionStatuses.LOADING_USER_SUCCESSFUL,
            SessionStatuses.LOADING_USER_FAILED,
            SessionStatuses.FORGOT_PASSWORD_STARTED,
            SessionStatuses.CONFIRM_EMAIL_STARTED,
            SessionStatuses.SET_PASSWORD_STARTED,
            SessionStatuses.NO_STATUS
        )
    );
    const currentLayout = computed<string>(
        () => (router?.app?.$route?.meta?.layout || "Default") + "Layout"
    );

    const utilityErrorNoAuthOrAuthExcludingSignInPage = computed<boolean>(
        () =>
            !!(
                router.app.$route?.name?.startsWith("utility-") ||
                router.app.$route?.name?.startsWith("error-") ||
                router.app.$route?.name?.startsWith("no-auth-") ||
                (router.app.$route?.name?.startsWith("auth-") && router.app.$route.name.toString() !== "auth-signin")
            )
    );

    const utilityErrorNoAuthOrAuth = computed<boolean>(
        () =>
            !!(
                router.app.$route?.name?.startsWith("utility-") ||
                router.app.$route?.name?.startsWith("error-") ||
                router.app.$route?.name?.startsWith("auth-") ||
                router.app.$route?.name?.startsWith("no-auth-")
            )
    );

    const bc = new BroadcastChannel(APP_SESSION_BROADCAST_CHANNEL, BROADCAST_CHANNEL_OPTIONS);

    bc.onmessage = async (messageEvent) => {
      if (!messageEvent) {
        return;
      }
      const {
        sessionStatus,
        diff
      } = typeof messageEvent?.sessionStatus === 'string' && typeof messageEvent?.diff === 'boolean' ? messageEvent : {
        sessionStatus: SessionStatuses.NO_STATUS,
        diff: false
      };

      const storageItem = localStorage.getItem(APP_LOCALSTORAGE_KEY);
      const commonData = storageItem ? JSON.parse(storageItem) : {};
      await Vue.nextTick();

      if (commonData?.SessionModule?.xsrfToken && commonData?.SessionModule?.xsrfToken !== store.getters[SessionGetters.XSRF_TOKEN]) {
        await store.commit(SessionMutations.SET_XSRF_TOKEN, commonData?.SessionModule?.xsrfToken);
      }

      if (sessionStatus === SessionStatuses.LOGIN_SUCCESSFUL && typeof commonData?.SessionModule?.user?.email === 'string' && commonData.SessionModule.user.email.length > 0) {
        await store.commit(SessionMutations.SET_USER, commonData?.SessionModule?.user);
        await store.commit(SessionMutations.SET_STATUS, SessionStatuses.NO_STATUS);
        await redirect(router.currentRoute, diff);
        return;
      }

      if (sessionStatus?.startsWith(SIGN_OUT) && store.getters[SessionGetters.USER]) {
        await store.commit(SessionMutations.SET_USER, undefined);
        await store.commit(SessionMutations.SET_STATUS, SessionStatuses.SIGN_OUT_SUCCESSFUL);
        return;
      }
    }

    const bcTheme = new BroadcastChannel(APP_UI_THEME_BROADCAST_CHANNEL, BROADCAST_CHANNEL_OPTIONS);
    bcTheme.onmessage = async (messageEvent: string) => {
      if (!messageEvent || (messageEvent !== Themes.LIGHT && messageEvent !== Themes.DARK)) {
        return;
      }

      store.commit(UIMutations.SET_GLOBAL_THEME, messageEvent);
      Vuetify.framework.theme.dark = messageEvent === Themes.DARK;
    }

    const {updateFilterTypeWithValue} = usePatientDataFilters();

    onBeforeMount(async () => {
      await store.dispatch(UIActions.LOAD_GLOBAL_THEME, {
        service: uIService,
      });
      await store.dispatch(UIActions.SAVE_BROWSER, {
        navigator: navigator,
      });
      if(shouldCheckForMaintenanceFile() && (store.getters[MaintenanceGetters.EXISTS] || !store.getters[MaintenanceGetters.REQUESTED])) {
        await store.dispatch(MaintenanceActions.LOAD_MAINTENANCE_FILE, {
          service: maintenanceService,
        });

        await Vue.nextTick();
        if (store.getters[MaintenanceGetters.EXISTS]) {
          await router.replace({name: "utility-maintenance"});
        }
      }

      //signout user if session is already expired to save errors from printing on screen
      if (store.getters[SessionGetters.AUTHENTICATED]) {
        const currentExpiry = localStorage.getItem(SESSION_EXPIRY_DATE);
        if (!currentExpiry || (moment.utc(currentExpiry).isValid() && moment.utc(currentExpiry).isBefore(moment.utc()))) {
          await store.dispatch(SessionActions.SIGN_OUT, {
            service: accountService,
          });
        }
      }
    });

    async function redirect(currentRoute: Route, diff: boolean = false) {
      if (diff) {
        await store.dispatch(PatientsActions.RESET, {
          patientDataFiltersOnly: true,
        });
        if (!(router.currentRoute.path?.toLowerCase() === '/' || router.currentRoute.path?.toLowerCase() === '/dashboard')) {
          await router.replace({name: "dashboard"});
        }
        return;
      }
      if (
          currentRoute.query.redirect &&
          currentRoute.query.redirect !== router.currentRoute.path
      ) {
        await router.push(currentRoute.query.redirect?.toString() || "/");
        return;
      } else if (router.currentRoute.path !== currentRoute.path && !utilityErrorNoAuthOrAuth.value) {
        await router.push(currentRoute.path || "/");
        return;
      } else if (!(router.currentRoute.path?.toLowerCase() === '/' || router.currentRoute.path?.toLowerCase() === '/dashboard')) {
        await router.replace({name: "dashboard"});
        return;
      }
    }

    const isDarkTheme = computed<boolean>(
        () => store.getters[UIGetters.IS_DARK_THEME]
    );

    const {patientDataFilters} = usePatientDataFilters();

    async function updatePatientDataFilter() {
      if (!store.getters[SessionGetters.AUTHENTICATED]) return;
      if (typeof patientDataFilters?.value !== 'undefined') {
        const updatedPatientDataFilters = cloneDeep(patientDataFilters.value);
        let dateRange = updatedPatientDataFilters.dateRange ? updatedPatientDataFilters.dateRange : 30;
        const dateRangeValues = getDateRange(dateRange);
        let fromDate = dateRangeValues?.fromDate
            ? dateRangeValues?.fromDate
            : patientDataFilters.value?.fromLastTouchedDate ? patientDataFilters.value?.fromLastTouchedDate : defaultFromDate;
        let toDate = dateRangeValues?.toDate
            ? dateRangeValues?.toDate
            : patientDataFilters.value?.toLastTouchedDate ? patientDataFilters.value?.toLastTouchedDate : undefined;

        if (!toDate) {
          const maxMomentDate = moment(fromDate as string).add(94, 'days');
          if (maxMomentDate.isBefore()) {
            fromDate = defaultFromDate;
            toDate = undefined;
            dateRange = 30;
          }
        }

        //if today make undefined
        toDate = toDate && moment.utc(toDate?.toString(), "YYYY-MM-DD").isSame(moment(), "day") ? undefined : toDate;
        updatedPatientDataFilters.statuses = undefined;
        updatedPatientDataFilters.includeTreatmentPlans = -1;
        if (fromDate !== patientDataFilters.value?.fromLastTouchedDate
            || toDate !== patientDataFilters.value?.toLastTouchedDate
            || dateRange !== patientDataFilters.value?.dateRange) {
          updatedPatientDataFilters.fromLastTouchedDate = fromDate;
          updatedPatientDataFilters.toLastTouchedDate = toDate;
          updatedPatientDataFilters.dateRange = dateRange;
        }
        store.cache.clear("PatientModule");
        await store.commit(PatientsMutations.SET_PATIENT_DATA_FILTERS, updatedPatientDataFilters);
      }
    }

    async function loadGeneralInfo(skipLoadClientConfigurations = false) {

      let preFetchDataPromises: Promise<any>[] = [
        store.dispatch(ClientSubscriptionsActions.LOAD_CLIENT_SUBSCRIPTIONS,{service: subscriptionService}),
        store.cache.dispatch(AppNotificationsActions.LOAD_NOTIFICATIONS, {service: appNotificationsService}),
        store.cache.dispatch(InputFieldsActions.LOAD_INPUT_FIELDS, {service: inputFieldsService}),
        store.cache.dispatch(TreatmentCoordinatorsActions.LOAD_TREATMENT_COORDINATORS, {service: treatmentCoordinatorService}),
        store.cache.dispatch(DoctorsActions.LOAD_DOCTORS, {service: doctorService}),
        store.cache.dispatch(OfficesActions.LOAD_OFFICES, {service: officeService}),
      ];

      if (!skipLoadClientConfigurations) {
        preFetchDataPromises.push(store.dispatch(ClientConfigurationSettingsActions.LOAD_CLIENT_CONFIGURATION_SETTINGS, {
          service: clientConfigurationSettingsService
        }));
      }

      if (preFetchDataPromises.length) await Promise.all(preFetchDataPromises);

    }
    function getClientId() {
      const storageItem = localStorage.getItem(APP_LOCALSTORAGE_KEY);
      const commonData = storageItem ? JSON.parse(storageItem) : {};
      return commonData?.ClientSubscriptionsModule?.clientId ? commonData.ClientSubscriptionsModule.clientId : "";
    }

    async function clearCache() {
      store.cache.clear();
      await store.dispatch("reset");
    }

    watch(
        isDarkTheme,
        (isDark) => {
          const bodyElement = document.getElementsByTagName("html")[0];
          if (isDark) {
            bodyElement.classList.add("dark");
            bodyElement.classList.remove("light");
          } else {
            bodyElement.classList.add("light");
            bodyElement.classList.remove("dark");
          }
        },
        {immediate: true}
    );

    watch(
        clientConfigurations,
        async (config) => {
          if (clientConfigurationsLastModified.value && moment.utc(clientConfigurationsLastModified.value).isValid() && config?.lastModifiedDate && moment.utc(config.lastModifiedDate).isValid() && moment.utc(clientConfigurationsLastModified.value).isBefore(moment.utc(config.lastModifiedDate))) {
            store.cache.clear();
            await store.commit(ClientConfigurationSettingsMutations.SET_LAST_MODIFIED_DATE, config?.lastModifiedDate);
            await loadGeneralInfo(true);
          }
        },
        {immediate: true}
    );

    watch(
        sessionStatus,
        async (currentStatus, prevStatus) => {
          if (currentStatus === prevStatus) {
            return;
          }

          let prevClientId = "";
          if (currentStatus === SessionStatuses.LOGIN_SUCCESSFUL) {
            prevClientId = getClientId();
          }
          switch (currentStatus) {
            case SessionStatuses.LOGIN_SUCCESSFUL:
              await updatePatientDataFilter(); //this has to be done before the loadGeneralInfo
              await loadGeneralInfo();
              await redirect(router.currentRoute, !prevClientId ? false : getClientId() !== prevClientId);
              await store.commit(SessionMutations.SET_STATUS, SessionStatuses.NO_STATUS);
              await bc.postMessage({
                sessionStatus: SessionStatuses.LOGIN_SUCCESSFUL,
                diff: getClientId() !== prevClientId
              });
              break;
            case SessionStatuses.LOADING_USER_SUCCESSFUL:
              await loadGeneralInfo();
              await store.commit(SessionMutations.SET_STATUS, SessionStatuses.NO_STATUS);
              break;
            case SessionStatuses.SIGN_OUT_FAILED:
            case SessionStatuses.SIGN_OUT_SUCCESSFUL:
              // eslint-disable-next-line no-case-declarations
              const currentPath = router?.currentRoute?.path;
              if (
                  currentPath &&
                  currentPath !== "/" &&
                  currentPath !== "/dashboard"
              ) {
                await router.push({
                  name: "dashboard",
                  query: {redirect: currentPath},
                });
              }
              await clearCache();
              //TODO look into something like this to clear store instead of reloading
              // https://www.npmjs.com/package/vuex-extensions
              // since it cause session to run again too.
              break;
            case SessionStatuses.SET_PASSWORD_SUCCESSFUL:
              await router.push({
                name: "auth-signin",
              });
              store.commit(SessionMutations.SET_STATUS, SessionStatuses.NO_STATUS);
              break;
            case SessionStatuses.VALIDATE_EMAIL:
              await router.push({name: "auth-verify-email"});
              store.commit(SessionMutations.SET_STATUS, SessionStatuses.NO_STATUS);
              break;
          }
        },
        {immediate: true}
    );

    return {
      authenticated,
      sessionLoading,
      appLoading,
      utilityErrorNoAuthOrAuthExcludingSignInPage,
      loading,
      currentLayout,
      sessionStatus,
      sessionStatusLoadingUser,
      subscriptionsLoading,
      inputFieldsLoading,
      utilityErrorNoAuthOrAuth,
      updateFilterTypeWithValue,
      clientConfigurations,
      routeMeta,
    };
  },
});
</script>

<style lang="scss">
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
