<template>
  <div :class="className"> <!-- patient  -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on:tooltip }">
        <v-badge
          :value="isBadgeVisible || add"
          :color="color"
          :dot="dot"
          :content="add ? '+' : content"
          :offset-y="offset"
          :offset-x="offset"
          :bottom="add"
          :bordered="false"
        >
          <template #badge v-if="add">
            <v-icon
                    x-small
                    style="cursor: pointer"
                    class="text--white"
                    color="white"
                    @click.stop.prevent="!openNewWindowUrl && routeTo ? clickNavigateToRoute($event, routeTo) : openNewWindowUrl ? goTo : undefined"
            >$plus
            </v-icon>
          </template>
          <v-btn
            icon
            small
            :exact="exact"
            :exact-path="exactPath"
            v-on="!$vuetify.breakpoint.mobile ? tooltip : undefined"
            :disabled="customButtonEnabled ? !customButtonEnabled : ((!isBadgeVisible && !add))"
            :href="openNewWindowUrl"
            :to="!openNewWindowUrl && routeTo ? routeTo : undefined"
            :target="openNewWindowUrl ? '_blank' : ''"
            :class="active ? 'v-btn--active' : ''"
            @click.stop="isSearch ? $emit('close') : undefined"
            v-blur
          >
            <font-awesome-layers full-width class="fa-2x" v-if="isSplit && icon === '$briefcase'">
              <font-awesome-icon :icon="['fas', 'briefcase-blank']" />
              <font-awesome-icon :icon="['fal', 'split']" transform="down-1 shrink-6" inverse />
            </font-awesome-layers>

            <v-icon
              v-else
              :color="(linkInformation || {}).isArchived && linkInformationKey === 'responsiblePartyIds' ? 'warning' : ''"
            >{{ icon }}
            </v-icon>
          </v-btn>
        </v-badge>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">

import Vue, { computed, defineComponent, ExtractPropTypes, PropType } from "vue";
import { DocumentInformation, LinkInformation } from "@shared/store/patients/interfaces";
import moment from "moment/moment";
import router from "../../../../router";
import { Location } from "vue-router/types/router";
import { useRouteComponents } from "@main/composables/helpers";
import { getStatusText } from "@shared/functions/statusFunctions";

const propsDefinition = {
  icon: {
    type: String as PropType<string>,
    required: false,
  },
  customColor: {
    type: String as PropType<string>,
    required: false,
  },
  customOffset: {
    type: Number as PropType<number>,
    required: false,
  },
  customContent: {
    type: [String, Number] as PropType<string | number>,
    required: false,
  },
  customDot: {
    type: Boolean as PropType<boolean>,
    required: false,
  },
  customIsBadgeVisible: {
    type: [String, Boolean] as PropType<string |boolean>,
    default: 'check',
  },
  customButtonEnabled: {
    type: Boolean as PropType<boolean>,
    required: false,
  },
  customTooltipText: {
    type: String as PropType<string>,
    required: false,
  },
  linkInformation: {
    type: Object as PropType<LinkInformation>,
    required: false,
  },
  linkInformationKey: {
    type: String as PropType<string>,
    required: false,
  },
  routeTo: {
    type: Object as PropType<Location>,
    required: false,
  },
  openNewWindowUrl: {
    type: String as PropType<string>,
    required: false,
  },
  type: {
    type: String as PropType<string>,
    required: false,
  },
  typeText: {
    type: String as PropType<string>,
    required: false,
  },
  className: {
    type: String as PropType<string>,
    default: 'pr-1',
  },
  isSearch: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  add: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  exact: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  exactPath: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  active: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  isSplit: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
};

type Props = ExtractPropTypes<typeof propsDefinition>;

export default defineComponent({
  name: "StatusItem",
  props: propsDefinition,
  components: {},
  setup({
          customColor,
          customContent,
          customDot,
          customOffset,
          linkInformation,
          linkInformationKey,
          routeTo,
          openNewWindowUrl,
          type,
          customIsBadgeVisible,
          customTooltipText,
          typeText,
          isSearch,
          add,
          isSplit,
          customButtonEnabled
        }: Props, { emit }) {
    const { clickNavigateToRoute } = useRouteComponents();
    const currentDateTime = moment();
    const length = computed<number>(() => {
        if (linkInformation && linkInformationKey && linkInformation[linkInformationKey as keyof LinkInformation]) {
          return Array.isArray(linkInformation[linkInformationKey as keyof LinkInformation])
            ? (linkInformation[linkInformationKey as keyof LinkInformation] as any[]).length
            : 1;
        }
        return 0;
      }
    );

    function getStatusColor(status?: number, dateStatusTriggered?: string) {
      let color = "grey";
      if (typeof status === "number") {
        switch (status) {
          case -1:
            color = "error";
            break;
          case 0: //sent or expiring
            color = type !== "signing" && dateStatusTriggered && currentDateTime.diff(moment(dateStatusTriggered), "days") > 23 ? "warning lighten-3" : "primary";
            break;
          case 1:
            //signing means partially signed instead of expired
            color = type === "signing" ? "warning lighten-3" : "warning";
            break;
          case 2:
            color = "success";
            break;
          case 3:
            color = "success";
            break;
          default:
            color = "grey";
          // code block
        }
      }
      return color;
    }

    const color = computed<string>(() => {
        if (customColor) {
          return customColor;
        }

        if (add) {
          return "grey";
        }

        let rtnVal = "grey";
        if (linkInformation && linkInformationKey && linkInformation[linkInformationKey as keyof LinkInformation]) {
          if (Array.isArray(linkInformation[linkInformationKey as keyof LinkInformation])
            && (linkInformation[linkInformationKey as keyof LinkInformation] as any[])?.length
            && (linkInformation[linkInformationKey as keyof LinkInformation] as any[])[0]
          ) { //array with values
            if (typeof (linkInformation[linkInformationKey as keyof LinkInformation] as any[])[0] !== "string") { //array of objects
              const value = (linkInformation[linkInformationKey as keyof LinkInformation] as any[])[0];
              rtnVal = getStatusColor(value?.status, value?.dateStatusTriggered);
            } else { //array but on strings
              rtnVal = "success";
            }// end array but on strings
          }//end array with values
          else if (linkInformation[linkInformationKey as keyof LinkInformation]) {
            const value = linkInformation[linkInformationKey as keyof LinkInformation];
            if (value && linkInformationKey !== "insuranceVerification") {
              rtnVal = getStatusColor((value as unknown as DocumentInformation)?.status, (value as unknown as DocumentInformation)?.dateStatusTriggered);
            }
            //TODO handle insurance verification differently here since it is of a different type
          }
        }
        return rtnVal;
      }
    );

    const tooltipText = computed<string>(() => {
        if (customTooltipText) {
          return customTooltipText;
        }
        let displayText = "";
        if (typeText) {
          displayText += typeText;
        }

        // (linkInformation[linkInformationKey as keyof LinkInformation] as any[])?.patientResponsibleParty
        if (linkInformation && linkInformationKey && linkInformation[linkInformationKey as keyof LinkInformation]) {
          if (Array.isArray(linkInformation[linkInformationKey as keyof LinkInformation])
            && (linkInformation[linkInformationKey as keyof LinkInformation] as any[])?.length
            && (linkInformation[linkInformationKey as keyof LinkInformation] as any[])[0]
          ) { //array with values
            if (typeof (linkInformation[linkInformationKey as keyof LinkInformation] as any[])[0] !== "string") { //array of objects
              const value = (linkInformation[linkInformationKey as keyof LinkInformation] as any[])[0];
              displayText += getStatusText(type, value?.status, value?.dateStatusTriggered);
            } else { //array but on strings
              displayText += " sent";
            }// end array but on strings
          }//end array with values
          else if (linkInformation[linkInformationKey as keyof LinkInformation]) {
            const value = linkInformation[linkInformationKey as keyof LinkInformation];
            if (value && linkInformationKey !== "insuranceVerification") {
              displayText += getStatusText(type, (value as unknown as DocumentInformation)?.status, (value as unknown as DocumentInformation)?.dateStatusTriggered);
            }
          }
        }

        //get status type and text

        return displayText;
      }
    );

    const offset = computed<number>(() => {
        if (customOffset) {
          return customOffset;
        }
        if (add) {
          return 13;
        }
        return length.value > 1 ? 10 : 8;
      }
    );

    const content = computed<number | string>(() => {
        if (customContent) {
          return customContent;
        }
        return length.value;
      }
    );

    const dot = computed<boolean>(() => {
        if (customDot) {
          return customDot;
        }

        if (add) {
          return false;
        }

        return !(length.value > 1);
      }
    );

    const isBadgeVisible = computed<boolean>(() => {
        if (typeof customIsBadgeVisible !== 'undefined' && customIsBadgeVisible !== 'check') {
          return !!customIsBadgeVisible;
        }
        if (linkInformation && linkInformationKey && linkInformation[linkInformationKey as keyof LinkInformation]) {
          return Array.isArray(linkInformation[linkInformationKey as keyof LinkInformation])
            ? !!(linkInformation[linkInformationKey as keyof LinkInformation] as any[]).length
            : true;
        }
        return false;
      }
    );

    async function goTo() {
      if (openNewWindowUrl) {
        window.open(openNewWindowUrl, "_blank");
      }
      if (routeTo) {
        await router.push(routeTo);
      }
      if (isSearch) emit("close");
    }

    return { color, offset, content, dot, isBadgeVisible, tooltipText, goTo, clickNavigateToRoute };
  }
})
;
</script>


