<template>
  <div class="d-flex text-center flex-column flex-md-row flex-grow-1">
    <v-sheet
      class="layout-side mx-auto mx-md-1 d-none d-md-flex flex-md-column justify-space-between px-2"
    >
      <div class="mt-3 mt-md-10 pa-2">
        <div class="display-2 font-weight-bold primary--text">orthodocsPro</div>
        <div class="title my-2">
          Welcome! Let's make our patients happy together.
        </div>
        <v-btn :href="landingSiteUrl" class="my-4">Take me back</v-btn>
      </div>
      <img class="w-full" src="/images/illustrations/signin-illustration.svg" />
    </v-sheet>

    <div
      class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column"
    >
      <div class="layout-content ma-auto w-full">
        <slot></slot>
      </div>
      <div class="overline mt-4">{{ APP_NAME }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {APP_NAME} from "../store/constants";

export default defineComponent({
  name: "AuthLayout",
  setup() {
    const landingSiteUrl = process.env.VUE_APP_LANDING_SITE_URL;

    return {
      APP_NAME,
      landingSiteUrl,
    }
  }
});
</script>

<style scoped lang="scss">
.layout-side {
  width: 420px;
}

.layout-content {
  max-width: 480px;
}
</style>
